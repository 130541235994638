<template>
    <v-container fluid>
        <v-form @submit.prevent="getClients('search')">
            <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                <v-card flat>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12">
                                <v-subheader class="headline">{{ $t('search_client') }}</v-subheader>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="py-0" cols="12" sm="4">
                                <ValidationProvider ref="client" rules="min:1|max:255"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="client" type="text" :error-messages="errors"
                                                  :disabled="loading" prepend-icon="mdi-account"
                                                  :label="$t('client_name')" color="primary"
                                                  clearable></v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="3">
                                <ValidationProvider ref="phone" rules="phone"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="phoneRaw" type="tel"
                                                  v-mask="['+# (###) ###-##-##', '+## (###) ###-##-##', '+### (###) ###-##-##']"
                                                  :error-messages="errors" :disabled="loading"
                                                  prepend-icon="mdi-phone" :label="$t('phone')"
                                                  color="primary" clearable>
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="3">
                                <ValidationProvider ref="email" rules="email|min:5|max:50"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="email" type="email" :error-messages="errors"
                                                  :disabled="loading"
                                                  prepend-icon="mdi-email" :label="$t('email')"
                                                  color="primary" clearable>
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="2">
                                <v-select v-model="active" :items="activeItems" :disabled="loading"
                                          item-text="name" item-value="id" prepend-icon="mdi-check"
                                          :label="$t('active')"></v-select>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions class="px-4 py-4">
                        <v-row>
                            <v-col cols="12" sm="3" md="2">
                                <v-select :value="perPage" :items="perPageItems" :disabled="loading"
                                          :label="$t('items_per_page')" prepend-icon="mdi-counter"
                                          @input="perPage = options.itemsPerPage = Number($event)"
                                          hide-details dense></v-select>
                            </v-col>
                            <v-col cols="12" sm="9" md="10"
                                   class="d-flex justify-center justify-sm-end align-self-center">
                                <v-btn type="submit" :disabled="invalid || loading"
                                       :block="$vuetify.breakpoint.xsOnly" color="primary">
                                    {{ $t('search') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </v-card>
            </ValidationObserver>
        </v-form>
        <v-card flat>
            <v-card-text>
                <v-data-table :headers="filteredHeaders" :items="clients" :options.sync="options"
                              :page.sync="page" :items-per-page="perPage" :server-items-length="totalClients"
                              :sort-by.sync="sortBy" :sort-desc.sync="sortDir" :loading="loading" :locale="lang"
                              @page-count="pageCount = options.pageCount = Number($event)"
                              :loading-text="$t('loading_please_wait')" class="elevation-1"
                              hide-default-footer>
                    <template v-slot:item.name="{ item }">
                        <div :class="item.deleted ? 'red--text' : ''">
                            {{ item.name }}
                            {{ item.deleted ? ' (' + $t('deleted') + ')' : '' }}
                        </div>
                    </template>
                    <template v-slot:item.active="{ item }">
                        {{ item.active ? $t('yes') : $t('no')}}
                    </template>
                    <template v-slot:item.action="{ item }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on"  @click="editUser(item)" icon>
                                    <v-icon>mdi-square-edit-outline</v-icon>
                                </v-btn>
                            </template>
                            <span>{{$t('edit')}}</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on" :disabled="item.deleted" @click="deleteUser(item)" icon>
                                    <v-icon>mdi-delete-outline</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ item.deleted ? $t('remotely') : $t('delete') }}</span>
                        </v-tooltip>
                    </template>
                </v-data-table>
            </v-card-text>
            <v-card-actions class="justify-center">
                <v-pagination v-model="page" :total-visible="totalVisiblePag"
                              :length="pageCount" :disabled="loading"></v-pagination>
            </v-card-actions>
        </v-card>
    </v-container>
</template>
<script>

    import { mapGetters } from "vuex"
    import { ValidationObserver, ValidationProvider } from 'vee-validate'
    import { mask } from 'vue-the-mask'

    export default {
        name: "Clients",
        directives: {
            mask,
        },
        components: {
            ValidationProvider,
            ValidationObserver
        },
        data() {
            return {
                fab: false,
                phoneRaw: null,
                client: null,
                email: null,
                clients: [],
                clientItems: [],
                active: 1,
                activeItems: [
                    {
                        id: null,
                        name: this.$t('all')
                    },
                    {
                        id: 1,
                        name: this.$t('yes')
                    },
                    {
                        id: 0,
                        name: this.$t('no')
                    }
                ],
                sortBy: "name",
                sortDir: true,
                page: 1,
                pageCount: 0,
                perPage: 5,
                totalVisiblePag: 5,
                totalClients: 0,
                options: {},
                loading: false,
                loadingCities: false,
                city: null,
                cityItems: [],
                citySearching: null,
                headers: [
                    {
                        text: this.$t('client'),
                        align: "left",
                        sortable: true,
                        value: "name"
                    },
                    {
                        text: this.$t('phone'),
                        align: "left",
                        sortable: true,
                        value: "phone"
                    },
                    {
                        text: this.$t('email'),
                        align: "left",
                        sortable: true,
                        value: "email"
                    },
                    {
                        text: this.$t('active'),
                        align: "center",
                        sortable: true,
                        value: "active",
                        width: 110,
                    },
                    {
                        text: this.$t('actions'),
                        align: "center",
                        value: 'action',
                        sortable: false,
                        width: 120,
                    },
                ]
            };
        },
        computed: {
            ...mapGetters(['lang', 'itemsPerPage', 'perPageItems', 'phoneMask']),
            phone: function () {
                return this.changePhone(this.phoneRaw)
            },
            filteredHeaders() {
                return this.headers.filter(h => !h.hide)
            },
        },
        mounted() {
            this.options.itemsPerPage = this.perPage = this.itemsPerPage
        },
        watch: {
            options: {
                handler() {
                    this.getClients()
                },
                deep: false
            }
        },
        methods: {
            editUser(item) {
                this.$router.push({
                    name: 'client.profile',
                    params: {
                        id: item.id
                    }
                })
            },
            async deleteUser(item) {
                if (confirm(this.$t('delete_client'))) {
                    var _this = this
                    this.loading = true;
                    await this.$http
                        .delete(`admin/client/${item.id}`)
                        .then(res => {
                            this.$toastr.success(this.$t('client_has_been_deleted'))
                            this.getClients()
                        })
                        .catch(err => {
                            this.$toastr.success(this.$t('client_has_not_been_deleted'))
                            if (err.body.message) {
                                for (let prop in err.body.errors) {
                                    if (hasOwnProperty.call(err.body.errors, prop)) {
                                        if (_this.$refs[prop]) {
                                            _this.$refs[prop].setErrors([
                                                err.body.errors[prop][0]
                                            ])
                                        }
                                    }
                                }
                                if (!err.body.errors) {
                                    this.$toastr.error(err.body.message)
                                }
                            }
                        })
                        .finally(end => {
                            this.loading = false
                        })
                }
            },
            async getClients(type) {
                this.loading = true;
                const {
                    sortBy,
                    sortDesc,
                    page,
                    itemsPerPage
                } = this.options;
                let params = {};
                if (sortBy[0] !== undefined) {
                    params.sortBy = sortBy[0];
                }
                if (sortDesc[0] !== undefined) {
                    params.sortDir = sortDesc[0] ? 'asc' : 'desc';
                }
                if (page !== undefined) {
                    if (type === 'search') {
                        params.page = 1
                    } else {
                        params.page = page
                    }
                }
                if (itemsPerPage !== undefined) {
                    params.perPage = itemsPerPage;
                }
                if (this.client) {
                    params.client = this.client;
                }
                if (this.phone) {
                    params.phone = this.phone;
                }
                if (this.email) {
                    params.email = this.email;
                }
                params.active = this.active

                await this.$http
                    .get("admin/client", {
                        params: params,
                    })
                    .then(res => {
                        this.clients = res.body.data
                        this.page = res.body.meta.current_page
                        this.totalClients = res.body.meta.total
                        this.pageCount = res.body.meta.last_page
                    })
                    .catch(err => {
                        this.clients = []
                        this.totalClients = 0
                        this.$toastr.error(this.$t('failed_to_get_list_clients'))
                    })
                    .finally(end => {
                        this.loading = false
                    })
            }
        }
    }
</script>
